<template>
  <div id="cart" :class="{ opened: showCart }">
    <div id="cart-block" @click="$emit('show-cart')">
      <img src="~assets/images/cart.svg" />
      <div v-if="cartSize" class="size">{{ cartSize }}</div>
    </div>
    <div id="cart-wrapper" data-sticky-for="800" data-margin-top="20">
      <div id="cart-info">
        <div class="title">Carrito</div>
        <span v-if="cart.length == 0">Tu carrito está vacío</span>
        <div class="cart-items">
          <div v-bind:key="cartItem.id" v-for="cartItem in cart">
            <cart-item
              :cartItem="cartItem"
              :codeDiscount="codeDiscount"
              v-on="$listeners"
            />
          </div>
          <div class="item" v-if="membership && cart.length > 0">
            <div class="desc">(única vez)</div>
            <div class="label">
              Matrícula
              <div class="info" @click="showModal = true">
                <img src="~assets/images/info.svg" />
              </div>
            </div>
            <div class="price">{{ membership | toPrice }}</div>
            <modal v-if="showModal" @close="showModal = false">
              <div slot="header">Matrícula</div>
              <h3 slot="body">{{ membershipDesc }}</h3>
            </modal>
          </div>
          <div class="item" v-if="cart.length > 0 && validCode">
            <div class="desc">(código '{{ code }}')</div>
            <div class="label">Descuento</div>
            <div class="price">{{ codeDiscount }}%</div>
            <div class="disclaimer">
              El código de descuento no aplica al valor de la matrícula.
            </div>
          </div>
        </div>
      </div>
      <div class="item totals" v-if="cart.length > 0">
        <div class="label">Total</div>
        <div class="price">{{ cartTotal | toPrice }}</div>
      </div>
      <div class="code-wrapper" v-if="!validCode">
        <label for="code">¿Tenés código de descuento?</label>
        <div class="code">
          <input
            v-model="code"
            type="text"
            class="input"
            placeholder="Código"
            name="code"
          />
          <bas-button @clicked="$emit('check-code', code)">Aplicar</bas-button>
        </div>
      </div>
      <bas-button
        v-if="cart.length > 0"
        @clicked="$emit('to-payment')"
        id="to-checkout"
        >Pagar con Mercadopago</bas-button
      >
    </div>
  </div>
</template>

<script>
import CartItem from "./CartItem";
import BasButton from "./BasButton";
import Modal from "./Modal";
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import Sticky from "sticky-js";

export default {
  name: "Cart",
  props: [
    "cart",
    "cartSize",
    "cartSubTotal",
    "membership",
    "cartTotal",
    "membershipDesc",
    "showCart",
    "validCode",
    "codeDiscount",
  ],
  components: {
    CartItem,
    BasButton,
    Modal,
    Datepicker,
  },
  data: function () {
    return {
      showModal: false,
      code: null,
      es: es,
      customDate: false,
      disabledDates: null,
      subscriptionDate: moment().toDate(),
    };
  },
  created() {
    let minDate = moment().subtract(1, "days");
    let maxDate = moment().add(1, "month").subtract(1, "days");
    this.disabledDates = {
      to: minDate.toDate(),
      from: maxDate.toDate(),
    };
    let sticky = new Sticky("#cart-wrapper");
  },
  methods: {
    updateSubscriptionDate: function () {
      this.$emit("update-subcription-date", this.subscriptionDate.valueOf());
    },
  },
  filters: {
    toPrice: function (price) {
      return parseFloat(price).format(2, 3, ".", ",");
    },
  },
};
</script>

<style scoped lang="scss">
#cart {
  position: fixed;
  bottom: 0;
  letter-spacing: 0.03rem;
  right: 0;
  width: 5rem;
  height: 5rem;
  border: none;
  box-shadow: none;
  background: var(--main-color);
  transition: width 0.3s ease-in 0.3s, height 0.3s ease-in-out 0.6s, background-color 1s ease 0.6s, padding 1s ease 0.2s;
  z-index: 99;
  overflow: hidden;
  &.opened {
    width: 100vw;
    height: calc((var(--vh, 1vh) * 100));
    background: var(--gray-color);
    padding: 3rem 2rem;
    z-index: 1000;
    overflow-y: auto;
    #cart-wrapper {
      opacity: 1;
      pointer-events: all;
    }
    #cart-block {
      background: var(--gray-color);
      z-index: 100;
      .size {
        border-color: var(--gray-color);
      }
    }
    #cart-info {
      opacity: 1;
      pointer-events: all;
      transition: all 0.2s ease 0.8s;
    }
  }
  #cart-wrapper {
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  #cart-block {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 5rem;
    height: 5rem;
    background: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 1s ease 0.3s;
    img {
      width: 2.2rem;
      height: auto;
    }
    .size {
      position: absolute;
      background: var(--black-color);
      color: #fff;
      width: 1.7rem;
      border-radius: 50%;
      right: 0.9rem;
      top: 0.9rem;
      font-size: 0.8rem;
      height: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid var(--main-color);
      font-weight: 600;
      transition: border-color 1s ease;
    }
  }
  #cart-info {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease 0;
    .cart-items {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 0.8rem;
    }
  }
  #to-checkout {
    margin-top: 1rem;
    margin-right: 0.5rem;
  }
  .item {
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    &.totals {
      margin-top: 1.5rem;
    }
    .desc {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
    .label {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 0;
      display: flex;
      .info {
        margin-left: 0.4rem;
      }
    }
    .price {
      font-size: 1.3rem;
      font-weight: 300;
    }
  }
}
.code-wrapper,
.date-wrapper {
  margin: 2rem 0;
  label {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block;
  }
  .code,
  .date {
    display: flex;
    align-items: center;
    input {
      margin-right: 1rem;
      text-transform: uppercase;
    }
    input,
    button,
    /deep/ .datefield {
      height: 3.5rem;
      margin-top: 0;
    }
  }
  .custom-date {
    span {
      font-size: 1.2rem;
    }
  }
  .text-link {
    margin-left: 1rem;
    border-bottom: 1px solid var(--secondary-color);
  }
}
.disclaimer {
  line-height: 1.2;
  margin-top: 0.4rem;
  color: #969696;
  border-top: 1px solid #ddd;
  padding-top: 0.4rem;
}
@media screen and (min-width: 800px) {
  #cart-block {
    display: none !important;
  }
  #cart {
    grid-area: cart;
    position: relative !important;
    right: 0 !important;
    width: 100% !important;
    height: max-content !important;
    overflow: visible !important;
    background: none;
    transition: none !important;
    .title {
      font-size: 1.4rem !important;
    }
    #cart-wrapper {
      opacity: 1;
      pointer-events: all;
      background: var(--gray-color) !important;
      padding: 2rem 2rem !important;
      overflow-y: auto;
      max-height: 95vh;
    }
    #cart-block {
      background: var(--gray-color);
      z-index: 100;
      .size {
        border-color: var(--gray-color);
      }
    }
    #cart-info {
      opacity: 1 !important;
      pointer-events: all;
      transition: all 0.2s ease 0.8s;
    }
  }
  .code-wrapper,
  .date-wrapper {
    label {
      font-size: 0.9rem;
    }
  }
}
</style>
