<template>
  <div>
    <div v-bind:key="day.id" v-for="day in days">
      <day
        v-bind:day="day"
        v-if="validateShow(day)"
        :can-sign-up="canSignUp"
        :only-inscribed="onlyInscribed"
        :limit="limit"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import Day from "./Day.vue";

export default {
  name: 'Week',
  components: {
    Day
  },
  props: ["days", "canSignUp", "only-inscribed", "limit"],
  methods:{
    validateShow(day){
      if (this.onlyInscribed){
        return day.klasses.filter((klass) => klass.insc_id).length;
      } else {
        return day.klasses.length;
      }
    }
  }
}
</script>

<style scoped>

</style>