<template>
  <div id="next-classes">
    <div class="title flex">
      <div>Elegir clases:</div>
      <div v-if="!loading" class="selected-count">
        {{ totalSelected }} / {{ totalAvailable }}
      </div>
    </div>
    <div v-if="loading" class="loading">Cargando...</div>
    <div v-else class="classes-picker">
      <div v-bind:key="day.date" v-for="day in classesList" class="day">
        <div class="day">
          <div class="subtitle i-wb">
            <span>{{ day.date | dayName }}</span>
            {{ day.date | date }}
          </div>
          <div class="classes-list">
            <div
              v-bind:key="index"
              v-for="(klass, index) in day.klasses"
              class="class-wrapper"
              v-bind:class="{
                joined: klass.selected,
                inscribed: klass.insc_id > 0,
              }"
              @click="toggleSelected(klass)"
              v-show="klass.inscriptions_count < klass.max_clients"
            >
              <div class="class">
                <div class="time">
                  <div class="starts">{{ klass.time | toTime }}</div>
                  <div class="duration">
                    ({{ klass.duration | toDuration }})
                  </div>
                </div>
                <div class="info">
                  <div class="name">{{ klass.activity }}</div>
                  <div class="professor">{{ klass.professor }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action-list">
      <BasButton @clicked="cancel" class="secondary">Cancelar</BasButton>
      <BasButton @clicked="addToCart" :class="{ disabled: loading }">{{
        confirmButtonText || "Agregar al carrito"
      }}</BasButton>
    </div>
  </div>
</template>

<script>
import BasButton from "./BasButton";

import axios from "axios";
import moment from "moment";
import Noty from "noty";

export default {
  name: "ClassesPicker",
  props: ["plan", "confirmButtonText", "new"],
  data() {
    return {
      classesList: [],
      activities: [],
      totalAvailable: 0,
      totalSelected: 0,
      loading: true,
    };
  },
  components: {
    BasButton,
  },
  created() {
    let params;

    if (this.new) {
      params = { plan_id: this.plan };
    } else {
      params = { subscription_id: this.plan };
    }

    axios
      .get("/api/klasses/classes_picker", { params: params })
      .then((response) => {
        console.log(response.data);
        this.classesList = response.data.classesList;
        this.loading = false;
        this.activities = response.data.activities;
        if (this.new) {
          this.totalAvailable = response.data.totalAvailable;
        } else {
          this.totalAvailable = this.activities[0].availableClasses;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    toggleSelected: function (picked) {
      if (
        picked.selected == true ||
        this.totalAvailable - this.totalSelected > 0
      ) {
        this.classesList = this.classesList.map((day) => {
          day.klasses.map((klass) => {
            if (picked == klass) {
              this.$set(klass, "selected", !klass.selected);
            }
            return klass;
          });
          return day;
        });
      } else {
        new Noty({
          text: "Ya te anotaste en todas tus clases disponibles.",
          layout: "bottomLeft",
          timeout: 3000,
          type: "alert",
          killer: true,
          theme: "bas",
        }).show();
      }
    },
    calculateTotalSelected: function () {
      this.totalSelected = this.classesList.reduce((total, day) => {
        let dayTotal = day.klasses.reduce((dt, klass) => {
          if (klass.selected) return dt + 1;
          else return dt;
        }, 0);

        return total + dayTotal;
      }, 0);
    },
    addToCart: function () {
      //if (this.totalSelected == this.totalAvailable) {
      let selectedClasses = this.classesList.reduce((selected, day) => {
        let dayTotal = day.klasses.filter((klass) => {
          return klass.selected == true;
        });
        return selected.concat(dayTotal);
      }, []);

      selectedClasses = selectedClasses.reduce((reduced, klass) => {
        return reduced.concat({ id: klass.id, date: klass.date });
      }, []);

      this.$emit("add-to-cart", selectedClasses);
      /* } else {
        new Noty({
          text: "Tenés que seleccionar el total de tus clases.",
          layout: "bottomLeft",
          timeout: 3000,
          type: "alert",
          killer: true,
          theme: "bas",
        }).show();
      } */
    },
    cancel: function () {
      this.$emit("cancel");
    },
  },
  filters: {
    date: function (date) {
      require("moment/locale/es");
      return moment.utc(Date.parse(date)).format("DD/MM/YYYY");
    },
    dayName: function (date) {
      require("moment/locale/es");
      return moment.utc(Date.parse(date)).format("dddd");
    },
    toTime: function (time) {
      require("moment/locale/es");
      return moment.utc(time.pad(4), "HHmm").format("HH:mm [hs]");
    },
    toDuration: function (duration) {
      require("moment/locale/es");
      return duration + " min";
      //return convertMinsToHrsMins(duration);
    },
    toMonth: function (date) {
      require("moment/locale/es");
      return moment.utc(date, "YYYY-MM-DD").format("MMM").slice(0, -1);
    },
    toDay: function (date) {
      require("moment/locale/es");
      return moment.utc(date, "YYYY-MM-DD").format("DD");
    },
  },
  watch: {
    classesList: function () {
      this.calculateTotalSelected();
    },
  },
};
</script>

<style scoped lang="scss">
#next-classes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1000;
  padding: 2rem 1.2rem;
  color: var(--black-color);
  transition: opacity 0.2s ease-in;
  opacity: 0;
  pointer-events: none;

  display: grid;
  grid-template-rows: 3rem minmax(0, 1fr) 4rem;

  &.opened {
    opacity: 1;
    pointer-events: all;
    animation: fadeIn 0.3s forwards;
  }
}
.day {
  margin: 1rem 0 2.5rem;
}
.subtitle {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 1.2rem;
  text-transform: uppercase;
  span {
    font-size: 1.1rem;
    font-weight: 600 !important;
    margin-left: 0.3rem;
  }
  &:after {
    background: #5f5f5f;
  }
}

#next-classes .classes-picker {
  overflow-y: auto;
  height: 100%;
}

.classes-list {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 0.4rem;
}

.action-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  button {
    height: 5rem;
    &.disabled {
      pointer-events: none;
      filter: grayscale(100%);
    }
  }
}

.title.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-count {
  padding: 0.4rem 0.8rem;
  background: var(--secondary-color);
  color: #fff;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.loading {
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
