<template>
  <div class="container">
    <cart id="cart" :cart="cart" :cart-total="cartTotal" :cart-size="cart.length" :membership="membership" :membership-desc="membershipDesc" :show-cart="showCart" :validCode="validCode" :codeDiscount="codeDiscount" v-on:remove-from-cart="removeFromCart" v-on:to-payment="toPayment" v-on:to-payment-cash="toPaymentCash" v-on:show-cart="showCart = !showCart" v-on:check-code="checkCode" v-on:update-subcription-date="updateSubcriptionDate" />
    <div class="title">
      <div class="decoration">Creá tu plan</div>
      Suscripciones
    </div>
    <div class="plans-wrapper">
      <div class="plan-filter">
        <div class="min-title">Filtrar</div>
        <div class="filter-list toggle">
          <div>
            <input type="radio" name="todos" v-model="filter" value id="todos" />
            <label for="todos">Todos</label>
          </div>
          <div v-bind:key="activity.id" v-for="activity in activities">
            <input type="radio" :name="activity" v-model="filter" :value="activity" :id="activity" />
            <label :for="activity">{{ activity }}</label>
          </div>
        </div>
      </div>
      <div class="plan-list">
        <div v-bind:key="plan.id" v-for="plan in filteredPlans">
          <plan v-bind:plan="plan" v-on:add-to-cart="addToCart" v-on:clear-other="clearOther" :active="plan.active" />
        </div>
      </div>
      <div class="highlighted" @click="showCart = !showCart">Ver carrito</div>
    </div>

    <div id="modal-box" class="modal micromodal-slide" aria-hidden="true">
      <div class="modal__overlay" data-micromodal-close="" tabindex="-1">
        <div class="modal__container" aria-labelledby="modal-1-title" aria-modal="true" role="dialog">
          <h1>
            Seleccionando tus clases asegurás tu lugar fijo durante todo el mes. Una vez elegidas, ya no pueden cambiarse.<br /><br />
            No olvides chequearlas :)
          </h1>
          <button class="button modal__btn" aria-label="Cerrar" data-micromodal-close="">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plan from "./components/Plan";
import Cart from "./components/Cart";
import BasButton from "./components/BasButton";
import axios from "axios";
import Noty from "noty";
import moment from "moment";
import MicroModal from "micromodal";

export default {
  name: "Subscription",
  components: {
    Plan,
    Cart,
    BasButton,
  },
  data() {
    return {
      plans: [],
      cartTotal: 0,
      tax: 0,
      cartSubTotal: 0,
      cart: [],
      checkoutBool: false,
      membership: null,
      membershipDesc: "",
      showCart: false,
      code: null,
      validCode: false,
      codeDiscount: null,
      activities: [],
      filter: "",
      subscriptionDate: Date.now(),
    };
  },
  created() {
    axios
      .get("/api/client/subscriptions_data")
      .then((response) => {
        this.plans = response.data.plans;
        response.data.plans.map((plan) => {
          this.$set(plan, "active", false);
          plan.activities.map((activity) => {
            if (!this.activities.includes(activity)) {
              this.activities.push(activity);
            }
          });
        });
        this.membership = response.data.membership_paid_at ? null : response.data.membership.cents / 100;
        this.membershipDesc = response.data.membership_desc;
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  mounted() {
    MicroModal.show("modal-box");
  },
  computed: {
    filteredPlans: function() {
      if (this.filter.length > 0) {
        return this.plans.filter((plan) => {
          return plan.activities.includes(this.filter);
        });
      } else {
        return this.plans;
      }
    },
  },
  methods: {
    addToCart: function(plan, selectedOption, price, selectedClasses) {
      this.plans = this.plans.map((loopPlan) => {
        if (loopPlan == plan) {
          this.$set(plan, "selected", true);
        }
        return loopPlan;
      });

      let cartItem = {
        plan: plan,
        selectedOption: selectedOption,
        price: price,
        selectedClasses: selectedClasses,
      };
      this.cart.push(cartItem);
    },
    removeFromCart(cartItem) {
      this.cart = this.cart.filter((loopCartItem) => loopCartItem != cartItem);
      this.plans = this.plans.map((loopPlan) => {
        if (loopPlan == cartItem.plan) {
          this.$set(loopPlan, "selected", false);
        }
        return loopPlan;
      });
    },
    toPayment() {
      this.sendPayment("mp");
    },
    toPaymentCash() {
      this.sendPayment("manual");
    },
    sendPayment(provider) {
      let selectedPlans = [];
      this.cart.map((cartItem) =>
        selectedPlans.push({
          plan_id: cartItem.plan.id,
          duration: cartItem.selectedOption,
          selectedClasses: cartItem.selectedClasses,
        })
      );
      console.log("Enviado: " + this.subscriptionDate + " - " + moment(this.subscriptionDate).format("YYYY-MM-DD"));
      axios
        .post("/api/client/subscribe", {
          plans: selectedPlans,
          pays_membership: this.membership != null,
          provider: provider,
          code: this.validCode ? this.code : null,
          date: moment(this.subscriptionDate).format("YYYY-MM-DD"),
        })
        .then(function(response) {
          console.log(response);
          if (response.data.saved == false) {
            new Noty({
              text: "Las siguientes clases ya fueron ocupadas: " + response.data.error,
              layout: "bottomCenter",
              timeout: 10000,
              type: "alert",
              killer: true,
              theme: "bas",
              id: "error-noty",
            }).show();
          } else {
            if (response.data.redirect_to) {
              document.location.href = response.data.redirect_to;
            } else {
              document.location.href = "/";
            }
          }
        })
        .catch(function(error) {
          alert(error);
        });
    },
    checkCode: function(code) {
      this.code = code;
      const vm = this;
      if (this.validCode) {
        new Noty({
          text: "Ya tenés un código aplicado",
          layout: "bottomLeft",
          timeout: 3000,
          type: "alert",
          killer: true,
          theme: "bas",
        }).show();

        return;
      }
      axios
        .get("/api/discount_codes/check/" + this.code)
        .then((response) => {
          let msg, alertType;

          if (response.data.valid) {
            this.codeDiscount = response.data.value.slice(0, -1);
            this.validCode = true;
            vm.calculateTotals();
            msg = "Código aplicado!";
            alertType = "notice";
          } else {
            msg = "Código inválido o vencido";
            alertType = "alert";
          }
          new Noty({
            text: msg,
            layout: "bottomLeft",
            timeout: 3000,
            type: alertType,
            killer: true,
            theme: "bas",
          }).show();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    calculateTotals: function() {
      this.cartTotal = this.membership;
      this.cartTotal += this.cart.reduce((total, cartItem) => {
        let price = cartItem.price / 100;
        price = this.validCode ? price * (1 - parseFloat(this.codeDiscount) / 100) : price;
        return total + price;
      }, 0);
    },
    clearOther: function(newActivePlan) {
      this.plans.map((plan) => {
        if (plan.id != newActivePlan.id) plan.active = false;
      });
    },
    updateSubcriptionDate: function(sDate) {
      console.log("Antes: " + this.subscriptionDate + " - " + sDate);
      this.subscriptionDate = sDate;
      console.log("Después: " + this.subscriptionDate + " - " + sDate);
    },
  },
  watch: {
    cart: function(val) {
      this.calculateTotals();
    },
  },
};
</script>

<style scoped lang="scss">
.plans-wrapper {
  /*max-width: 49rem;*/
}
.highlighted {
  display: inline-block;
  margin: 1rem 0;
}
.filter-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #f0f0f0;
  & > div {
    flex-grow: 1;
    label {
      width: 100%;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  }
}
.min-title {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}
@media screen and (min-width: 800px) {
  .plans-wrapper {
    grid-area: plans;
  }
  .title {
    grid-area: title;
  }
  .container {
    display: grid;
    grid-template-areas: "title title" "plans cart";
    grid-template-columns: 1.3fr 1fr;
    grid-column-gap: 2rem;
    margin-bottom: 2rem;
  }
  .filter-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & > div {
      flex-grow: unset;
      label {
        width: auto;
        font-size: 0.8rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  .highlighted {
    display: none;
  }
}
</style>
