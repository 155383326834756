<template>
  <div class="plan" :class="{ selected: plan.selected, active: this.optionIsSelected }">
    <div class="name">
      {{ plan.name }}
      <span v-if="plan.selected">(en carrito)</span>
      <div class="info" @click="showModal = true">
        <img src="~assets/images/info.svg" />
      </div>
    </div>
    <div class="options-wrapper">
      <div class="payment-options toggle">
        <input v-if="plan.price_cents > 0" type="radio" :name="'plan' + plan.id" value="month" :id="'month' + plan.id" @click="updateSelection" />
        <label v-if="plan.price_cents > 0" :for="'month' + plan.id">
          <div>Mes</div>
          <span>${{ plan.price_cents / 100 }}</span>
        </label>
        <input v-if="plan.semester_price_cents > 0" type="radio" :name="'plan' + plan.id" value="semester" :id="'semester' + plan.id" @click="updateSelection" />
        <label v-if="plan.semester_price_cents > 0" :for="'semester' + plan.id">
          <div>Semestre</div>
          <span>${{ plan.semester_price_cents / 100 }}</span>
        </label>
        <input v-if="plan.year_price_cents > 0" type="radio" :name="'plan' + plan.id" value="year" :id="'year' + plan.id" @click="updateSelection" />
        <label v-if="plan.year_price_cents > 0" :for="'year' + plan.id">
          <div>Año</div>
          <span>${{ plan.year_price_cents / 100 }}</span>
        </label>
        <input v-if="plan.day_price_cents > 0" type="radio" :name="'plan' + plan.id" value="day" :id="'day' + plan.id" @click="updateSelection" />
        <label v-if="plan.day_price_cents > 0" :for="'day' + plan.id">
          <div>{{ plan.main_activity_type }}</div>
          <span>${{ plan.day_price_cents / 100 }}</span>
        </label>
      </div>
      <div class="actions">
        <bas-button class="close" @clicked="clearSelection">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </bas-button>
        <bas-button class="add" @clicked="selectClasses">{{ plan.selected ? "Agregado" : "Elegir clases" }}</bas-button>
      </div>
    </div>
    <ClassesPicker v-if="showClassesPicker" v-on:add-to-cart="addToCart" v-on:cancel="cancel" :class="{ opened: showClassesPicker }" :plan="plan.id" :new="true" />
    <modal v-if="showModal" @close="showModal = false">
      <div slot="header">{{ plan.name }}</div>
      <h3 slot="body" v-html="plan.description"></h3>
      <div slot="body" class="morning-warning" v-show="plan.only_morning_shift">Este plan solo permite la inscripción a las clases del turno mañana.</div>
    </modal>
  </div>
</template>

<script>
import BasButton from "./BasButton";
import ClassesPicker from "./ClassesPicker";
import Modal from "./Modal";
import axios from "axios";

export default {
  name: "Plan",
  props: ["plan", "active"],
  data() {
    return {
      optionIsSelected: false,
      previousOption: null,
      day_price: this.plan.day_price_cents,
      month_price: this.plan.price_cents,
      semester_price: this.plan.semester_price_cents,
      year_price: this.plan.year_price_cents,
      showModal: false,
      showClassesPicker: false,
      classesPicked: [],
    };
  },
  components: {
    BasButton,
    Modal,
    ClassesPicker,
  },
  methods: {
    getSelectedOption() {
      return document.querySelector('input[name="plan' + this.plan.id + '"]:checked').value;
    },
    getPrice() {
      return this[this.getSelectedOption() + "_price"];
    },
    updateSelection: function(event) {
      this.$emit("clear-other", this.plan);
      this.optionIsSelected = event.target.id != this.previousOption;
      event.target.checked = this.optionIsSelected;
      this.previousOption = this.optionIsSelected ? event.target.id : null;
      this.plan.active = true;
    },
    addToCart: function(selectedClasses) {
      this.$emit("add-to-cart", this.plan, this.getSelectedOption(), this.getPrice(), selectedClasses);
      this.optionIsSelected = false;
      this.showClassesPicker = false;
    },
    cancel: function() {
      this.clearSelection();
      this.showClassesPicker = false;
    },
    clearSelection: function(event) {
      this.optionIsSelected = false;
      this.previousOption = null;
      //document.querySelector("input:checked").checked = false;
    },
    showInfo: function() {},
    selectClasses: function() {
      this.showClassesPicker = true;
    },
  },
  watch: {
    active: function(val) {
      if (val == false) {
        this.optionIsSelected = false;
        this.previousOption = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.plan-list {
  .plan:not(.selected) {
    .toggle input:checked + label {
      background: initial;
      /*border: solid 1px #ddd;*/
      color: #4a4a4a;
      span {
        font-weight: inherit;
      }
    }
  }
}
.plan {
  margin-bottom: 1.5rem;
  &.selected .payment-options {
    pointer-events: none;
  }
  .name {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    img {
      margin-left: 0.5rem;
    }
    span {
      font-weight: 300;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.05rem;
      margin-left: 0.4rem;
    }
    .info {
      position: relative;
      top: 0.2rem;
    }
  }
  label span:nth-child(2) {
    padding: 0 0.5rem;
  }
  .toggle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    input + label {
      display: grid;
      grid-template-rows: 1fr 1fr;
    }
  }
  .options-wrapper {
    position: relative;
    .actions {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100%;
      width: 100%;
      pointer-events: none;
      display: flex;
      overflow: hidden;
    }
    .button {
      position: absolute;
      background: var(--secondary-color);
      border-color: var(--secondary-color);
      color: #fff;
      margin: 0;
      width: calc(100% - 5rem);
      height: 100%;
      transform: translateX(calc(100% + 5rem));
      transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    }
    .add {
      right: 0;
    }
    .close {
      left: 0;
      background: var(--black-color);
      border-color: var(--black-color);
      margin: 0;
      padding: 0;
      width: 5rem;
      height: 100%;
      transform: translateX(-5rem);
      svg {
        width: 2.2rem;
        max-width: unset;
        height: 2.2rem;
        margin: 0;
      }
    }
  }
  &.active {
    .actions {
      pointer-events: all;
    }
    .payment-options {
      pointer-events: none;
    }
    .button,
    .close {
      transform: translateY(0);
    }
  }
}
.morning-warning {
  font-weight: 600;
  margin: 1rem 0;
  font-size: 1.1rem;
  line-height: 1.2;
  background: var(--secondary-color);
  color: #fff;
  padding: 0.8rem 1rem;
}
</style>
