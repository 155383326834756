<template>
  <button @click="$emit('clicked')" class="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BasButton',
}
</script>

<style scoped>
</style>