<template>
  <div class="cart-item">
    <div>
      <div class="type">(1 {{ cartItem.selectedOption | translate }})</div>
      <div class="name">{{ cartItem.plan.name }}</div>
      <div class="price" v-if="!codeDiscount">{{ cartItem.price/100 | toPrice }}</div>
      <div class="price discount-applied" v-if="codeDiscount">
        <span class="with-discount">{{ priceWithDiscount() }}</span>
        <span class="original">({{ cartItem.price/100 | toPrice }})</span>
      </div>
    </div>
    <div class="actions">
      <div class="remove" @click="$emit('remove-from-cart', cartItem)"><img src="~assets/images/add.svg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartItem',
  props: ["cartItem", 'codeDiscount'],
  filters: {
    translate: function (type) {
      switch (type) {
        case "day":
          return "Día"
          break;
        case "month":
          return "Mes"
          break;
        case "semester":
          return "Semestre"
          break;
        case "year":
          return "Año"
          break;
      }
    },
    toPrice: function (price){
      return parseFloat(price).format(2, 3, '.', ',');
    },
  },
  methods: {
    priceWithDiscount: function (){
      return parseFloat((this.cartItem.price/100)*(1-(parseFloat(this.codeDiscount)/100))).format(2, 3, '.', ',');
    },
  }
}
</script>

<style lang="scss" scoped>
.cart-item {
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 5fr 1fr;
  .type{
    font-weight: 300;
    text-transform: uppercase;
    font-size: .9rem;
  }
  .name {
    font-weight: 600;
    font-size: 1.1rem;
  }
  .price {
    margin-top: .2rem;
    font-size: 1.3rem;
    font-weight: 300;
    .original {
      opacity: .5;
      text-decoration: line-through;
    }
  }
  .actions{
    display: flex;
    align-items: top;
    justify-content: flex-end;
    .remove img{
      transform: rotate(45deg);
    }
  }
}
</style>