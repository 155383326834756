<template>
  <div>
    <bas-button id="show-classes" v-on:clicked="openClassesList">Clases</bas-button>
    <div id="classes-wrapper">
      <div id="next-classes" class="container">
        <bas-button class="close" v-on:clicked="closeClassesList">
          <img src="~assets/images/close.svg" />
        </bas-button>
        <div class="title">
          <div class="decoration">Inscribite</div>
          Próximas clases.
        </div>
        <week v-bind:days="days" v-bind:can-sign-up="true" class="week" v-on="$listeners" :limit="99" />
      </div>
    </div>
  </div>
</template>

<script>
import BasButton from "./BasButton";
import Week from "./Week";

export default {
  name: "ClassesHub",
  components: {
    BasButton,
    Week,
  },
  props: ["days"],
  methods: {
    openClassesList() {
      document.documentElement.classList.add("menu-opened");
      byid("classes-wrapper").classList.add("active");
    },
    closeClassesList() {
      document.documentElement.classList.remove("menu-opened");
      byid("classes-wrapper").classList.remove("active");
    },
  },
};
</script>

<style scoped lang="scss">
#classes-wrapper {
  background: var(--main-color);
  width: 14rem;
  height: 5rem;
  bottom: 0;
  right: 0;
  position: fixed;
  transition: width 0.3s ease-in 0s, height 0.3s ease-in-out 0.3s, background-color 1s ease 0.3s;
  opacity: 0;
  pointer-events: none;

  &.active {
    background-color: #fff;
    width: 100vw;
    height: calc((var(--vh, 1vh) * 100));
    opacity: 1;
    pointer-events: all;
    z-index: 1000;
  }
}
#show-classes {
  position: fixed;
  bottom: 0;
  letter-spacing: 0.03rem;
  right: 0;
  height: 4.5rem;
  border: none;
  box-shadow: none;
  z-index: 99;
  img {
    margin-right: 1rem;
    width: 2rem;
  }
}
#next-classes {
  height: 100%;
  padding: 2rem 1.2rem;
  color: var(--black-color);
  opacity: 0;
  transition: opacity 0.2s ease-in 0.8s;
}
#next-classes,
.title {
  color: var(--black-color);
}
#gym .title {
  margin-bottom: 1.5rem;
}
#classes-wrapper.active #next-classes {
  opacity: 1;
}
.close {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  font-weight: 300;
  z-index: 99;
  img {
    width: 2.2rem;
  }
}
@media (min-width: 900px) {
  .close {
    top: 3rem;
    right: 4rem;
  }
}
.week {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 5rem;
}
</style>
