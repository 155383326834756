<template>
  <div class="container">
    <div class="modules">
      <div class="module">
        <div class="subtitle first">Anotado</div>
        <div v-if="loadingClasses == true" class="no-class">
          Cargando...
        </div>
        <div v-else>
          <div v-if="hasIncomingClasses.length == 0" class="no-class">
            No estás anotado en ninguna clase.
          </div>
          <div id="my-classes">
            <div :key="index" v-for="(klass, index) in inscribedKlasses" class="klass">
              <div class="index">{{ index + 1 }}</div>
              <klass :klass="klass" v-on="$listeners" />
            </div>
          </div>
          <img class="more-klasses" src="~assets/images/arrow.svg" v-if="inscribedKlasses.length > 1" />
        </div>
        <!-- <ClassesPicker v-if="showClassesPicker && remainingClasses.length > 0" v-on:confirm-classes="confirmClasses" v-on:cancel="cancel" :class="{ opened: showClassesPicker }" :remainin /> -->
        <a href="/suscripciones" class="button" v-if="remainingClasses.length == 0">Agregar suscripción</a>
      </div>
      <div class="module" v-if="activeSubscriptions.length > 0">
        <div class="subtitle first">Clases disponibles</div>
        <div :key="subscription.id" v-for="subscription in activeSubscriptions" class="plan">
          <div class="">{{ subscription.name }}</div>
          <BasButton @clicked="showClassesPicker = subscription.id" class="compact">Elegir clases</BasButton>
          <ClassesPicker v-if="showClassesPicker == subscription.id" v-on:add-to-cart="confirmClasses($event, subscription.id)" v-on:cancel="cancelPicker" :class="{ opened: showClassesPicker }" :plan="subscription.id" confirm-button-text="Confirmar" />
        </div>
      </div>
      <div class="module attendance">
        <div class="subtitle">Historial de asistencia</div>
        <canvas id="myChart" width="400" height="200"></canvas>
      </div>
      <div class="module" v-if="false && remainingClasses.length > 0">
        <div class="subtitle i-wb">Clases restantes</div>
        <div class="remainin-classes">
          <div :key="index" v-for="(activity, index) in remainingClasses">
            <div>
              {{ activity.activity }}:
              <span>{{ activity.available >= 0 ? activity.available : "Libre" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Week from "./components/Week";
import Klass from "./components/Klass";
import ClassesHub from "./components/ClassesHub";
import ClassesPicker from "./components/ClassesPicker";
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import Chart from "chart.js";
import moment from "moment";
import BasButton from "./components/BasButton.vue";

export default {
  name: "Gym",
  components: {
    Week,
    ClassesHub,
    Klass,
    ClassesPicker,
    BasButton,
  },
  data() {
    return {
      days: [],
      inscribedKlasses: [],
      attendance: [],
      remainingClasses: [0],
      showClassesPicker: false,
      activeSubscriptions: [],
      loadingClasses: true,
    };
  },
  created() {
    this.fetchClientInfo();
  },
  methods: {
    fetchClientInfo() {
      const _this = this;
      this.loadingClasses = true;
      let today = new Date();
      let start = formatDate(today);
      let end = today;
      end.setDate(end.getDate() + 60);
      end = formatDate(end);

      this.activeSubscriptions = [];

      axios
        .get("/api/klasses/between?start=" + start + "&end=" + end)
        .then(function(response) {
          _this.days = response.data;
          _this.prepareKlasses();
          _this.fetchInscribedKlasses();
          _this.loadingClasses = false;
        })
        .catch(function(error) {
          console.log(error);
        });

      axios
        .get("/api/client/dashboard")
        .then(function(response) {
          _this.attendance = response.data.attendance_history;
          _this.remainingClasses = response.data.remaining_classes;
          _this.activeSubscriptions = response.data.active_plans;
          _this.drawChart();
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    confirmClasses(selectedClasses, plan) {
      const _this = this;
      axios
        .post("/api/client/add_inscriptions", {
          selectedClasses: selectedClasses,
          subscription_id: plan,
        })
        .then(function(response) {
          new Noty({
            text: "Te anotaste correctamente.",
            layout: "bottomLeft",
            timeout: 3000,
            type: "notice",
            killer: true,
            theme: "bas",
          }).show();
          _this.showClassesPicker = null;
          _this.fetchClientInfo();
        })
        .catch(function(error) {
          alert(error);
        });
    },
    cancelPicker() {
      this.showClassesPicker = null;
    },
    manageKlass(klass, dayDate) {
      const vm = this;
      let thisDays = this.days;
      let alertMsg = "";
      let alertType = "notice";
      if (klass.insc_id) {
        axios
          .delete("/api/client/remove_inscription", {
            data: {
              inscription_id: klass.insc_id,
            },
          })
          .then(function(response) {
            klass.insc_id = null;
            klass.processing = false;
            vm.remainingClasses = response.data;
            alertMsg = "Te desincribiste correctamente.";
            vm.updateKlass(klass, dayDate);
            new Noty({
              text: alertMsg,
              layout: "bottomLeft",
              timeout: 3000,
              type: alertType,
              killer: true,
              theme: "bas",
            }).show();
          })
          .catch(function(error) {
            alertMsg = error.response.data.errors;
            alertType = "alert";
            klass.processing = false;
            vm.updateKlass(klass, dayDate);
            new Noty({
              text: alertMsg,
              layout: "bottomLeft",
              timeout: 3000,
              type: alertType,
              killer: true,
              theme: "bas",
            }).show();
            console.log(error);
          });
      } else {
        axios
          .post("/api/client/add_inscription", {
            klass_id: klass.id,
            date: klass.date,
          })
          .then(function(response) {
            klass.insc_id = response.data.inscription.id;
            vm.remainingClasses = response.data.remaining_classes;
            alertMsg = "Te anotaste correctamente.";
            klass.processing = false;
            vm.updateKlass(klass, dayDate);
            new Noty({
              text: alertMsg,
              layout: "bottomLeft",
              timeout: 3000,
              type: alertType,
              killer: true,
              theme: "bas",
            }).show();
          })
          .catch(function(error) {
            alertMsg = error.response.data.errors;
            alertType = "alert";
            klass.processing = false;
            vm.updateKlass(klass, dayDate);
            new Noty({
              text: alertMsg,
              layout: "bottomLeft",
              timeout: 3000,
              type: alertType,
              killer: true,
              theme: "bas",
            }).show();
          });
      }
    },
    updateKlass(klass, dayDate) {
      this.days = this.days.map((day) => {
        if (day.date == dayDate) {
          day.klasses.map((klassLoop) => {
            klassLoop.id == klass.id ? klass : klassLoop;
          });
        }
        return day;
      });
      this.fetchInscribedKlasses();
    },
    fetchInscribedKlasses() {
      this.inscribedKlasses = [];
      this.days.map((day) => {
        day.klasses.map((klass) => {
          if (klass.insc_id) {
            this.inscribedKlasses.push(klass);
          }
        });
      });
    },
    prepareKlasses() {
      this.days.map((day) => {
        day.klasses.map((klass) => {
          this.$set(klass, "processing", false);
        });
      });
    },
    drawChart() {
      require("moment/locale/es");
      let _this = this;

      var ctx = document.getElementById("myChart");
      var myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.attendance.map((day) => {
            return moment.utc(day.date, "DD/M/YYYY").format("dd/DD");
          }),
          datasets: [
            {
              data: this.attendance.map((day) => {
                return day.inscription == true ? "Si" : "No";
              }),
              label: "",
              borderColor: "#8629ff",
              fill: false,
              steppedLine: true,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                type: "category",
                labels: ["", "Si", "No", ""],
              },
            ],
          },
        },
      });
    },
  },
  computed: {
    hasIncomingClasses: function() {
      return this.days.filter((day) => {
        let inscribed = day.klasses.filter((klass) => {
          return klass.insc_id;
        });
        return inscribed.length > 0;
      });
    },
  },
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.subtitle.first {
  margin-bottom: 0.5rem !important;
}
.klass {
  position: relative;
  .index {
    position: absolute;
    z-index: 1;
    right: 1rem;
    font-size: 2.4rem;
    font-weight: bolder;
    color: var(--main-color);
    top: -0.8rem;
  }
}
.remainin-classes {
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1rem;
  font-weight: 300;
  span {
    font-weight: 400;
  }
}
@media (max-width: 500px) {
  #my-classes {
    white-space: nowrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .klass {
    position: relative;
    display: inline-block;
    margin-right: 1rem;
    padding-top: 0.5rem;
    .class-wrapper {
      margin-bottom: 0 !important;
    }
  }
  .more-klasses {
    width: 1.2rem;
    position: absolute;
    right: 0.3rem;
    z-index: 1;
    margin-top: 0.5rem;
    animation-duration: 0.5s;
    animation-name: bounce-right;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-play-state: running;
  }
}
.plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  background: #f9f9f9;
  padding: 0.5rem 1rem;
  .button {
    margin: 0 0 0 1rem;
  }
}
</style>
