<template>
  <div class="class-wrapper" v-bind:class="{ joined: klass.insc_id, processing: klass.processing }" @click="manageKlass">
    <div class="class">
      <div class="time">
        <div class="date" v-if="!canSignUp">
          <div>{{ klass.date | toDay }}</div>
          <div>{{ klass.date | toMonth }}</div>
        </div>
        <div class="starts">{{ klass.time | toTime }}</div>
        <div class="duration">({{ klass.duration | toDuration }})</div>
      </div>
      <div class="info">
        <div class="name">{{ klass.activity }}</div>
        <div class="professor">{{ klass.professor }}</div>
      </div>
    </div>
    <div class="actions">
      <svg class="join-class" v-if="canSignUp" v-bind:class="{ joined: klass.insc_id }" @click="$emit('manage-klass', klass, klass.date)" x="0px" y="0px" viewBox="0 0 52 52" style="enable-background:new 0 0 52 52;" xml:space="preserve">
        <g>
          <path
            d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26
            S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z"
          />
          <path
            d="M38.5,25H27V14c0-0.553-0.448-1-1-1s-1,0.447-1,1v11H13.5c-0.552,0-1,0.447-1,1s0.448,1,1,1H25v12c0,0.553,0.448,1,1,1
            s1-0.447,1-1V27h11.5c0.552,0,1-0.447,1-1S39.052,25,38.5,25z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import BasButton from "./BasButton";
import moment from "moment";

export default {
  name: "Klass",
  components: {
    BasButton,
  },
  props: ["klass", "canSignUp"],
  methods: {
    manageKlass: function() {
      this.klass.processing = true;
      this.$emit("manage-klass", this.klass, this.klass.date);
    },
  },
  filters: {
    toTime: function(time) {
      require("moment/locale/es");
      return moment.utc(time.pad(4), "HHmm").format("HH:mm [hs]");
    },
    toDuration: function(duration) {
      require("moment/locale/es");
      return duration + " min";
      //return convertMinsToHrsMins(duration);
    },
    toTimePlusDuration: function(time, duration) {
      require("moment/locale/es");
      return moment
        .utc(time, "HHmm")
        .add(duration, "minutes")
        .format("HH:mm");
    },
    toDay: function(date) {
      require("moment/locale/es");
      return moment.utc(date, "YYYY-MM-DD").format("DD");
    },
    toMonth: function(date) {
      require("moment/locale/es");
      return moment
        .utc(date, "YYYY-MM-DD")
        .format("MMM")
        .slice(0, -1);
    },
  },
};
</script>

<style lang="scss">
.class-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  margin: 0.5rem 0;
  height: 100%;
  border-bottom: 1px solid #333;
  border: none;
  background: var(--gray-color);
  color: #222;
  padding: 0.5rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &.processing {
    pointer-events: none;
  }
}
#next-classes .class-wrapper:after {
  content: "";
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  width: 0;
  height: 1rem;
  border-radius: 50%;
  background: var(--secondary-color);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.class-wrapper.last {
  border: none;
}
.class {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1rem;
}
.date {
  grid-area: date;
}
.time {
  text-align: center;
  grid-template-rows: 1fr 1fr;
}
.starts {
  font-size: 1.2rem;
  grid-area: starts;
}
.duration {
  grid-area: duration;
  font-weight: 300;
}
.info {
  align-self: center;
}
.name {
  font-weight: 700;
  font-size: 1.2rem;
}
.actions {
  align-self: flex-end;
  text-align: right;
}
.actions svg {
  width: 2.2rem;
  margin-right: 0.5rem;
}
.join-class {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.join-class.joined {
  transform: rotate(45deg);
}
.join-class.joined path {
  fill: red;
}
#next-classes {
  .classes-list {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 0.4rem;
  }
  .class-wrapper {
    position: relative;
    display: block;
    margin: 0;
    border-bottom: 1px solid #333;
    border: none;
    background: var(--gray-color);
    color: #222;
    padding: 1rem 0.5rem;
    transition: all 0.3s ease-in;
    &.joined,
    &.inscribed {
      background: var(--secondary-color);
      color: #fff;
      transition: all 0.2s ease-in 0.1s;
      .class {
        animation-duration: 0.4s;
        animation-name: jump;
        animation-timing-function: ease;
        animation-direction: alternate;
        animation-fill-mode: forwards;
      }
    }
    &.inscribed {
      background: #9384b4;
      pointer-events: none;
    }
  }
  .class {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .time {
    text-align: center;
    grid-template-rows: 1fr 1fr;
    font-weight: 600;
  }
  .starts {
    font-size: 1.3rem;
    grid-area: starts;
    line-height: 1.1;
  }
  .duration {
    grid-area: duration;
    font-weight: 300;
  }
  .info {
    align-self: center;
    padding: 0.8rem 0.2rem 0;
  }
  .name {
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.2;
  }
  .actions {
    align-self: flex-end;
    text-align: right;
    display: none;
  }
}
@keyframes jump {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
