<template>
  <div class="day">
    <div class="subtitle i-wb">
      <span>{{ day.date | dayName }}</span>
      {{ day.date | date }}
    </div>
    <div class="classes-list">
      <div v-bind:key="klass.id" v-for="(klass, index) in day.klasses">
        <klass
          v-if="!onlyInscribed || (onlyInscribed && klass.insc_id)"
          v-bind:klass="klass"
          v-bind:can-sign-up="canSignUp"
          v-bind:class="{ last: (index == day.klasses.length - 1) }"
          v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Klass from "./Klass";
import moment from "moment";

export default {
  name: "Day",
  components: {
    Klass
  },
  props: ["day", "canSignUp", "only-inscribed", "limit"],
  filters: {
    date: function(date) {
      require("moment/locale/es");
      return moment.utc(Date.parse(date)).format("DD/MM/YYYY");
    },
    dayName: function(date) {
      require("moment/locale/es");
      return moment.utc(Date.parse(date)).format("dddd");
    }
  }
};
</script>

<style scoped lang='scss'>
.day {
  margin: 1rem 0 2.5rem;
}
.subtitle {
  font-weight: 400;
  text-transform: capitalize;
  span {
    font-weight: 600 !important;
    text-transform: uppercase;
    margin-right: 0.3rem;
  }
  &:after {
    background: #5f5f5f;
  }
}
#next-classes .subtitle {
  color: var(--black-color);
}
/*.classes-list > div:nth-child(even) .class-wrapper.clicked {
  transform: scale(1.1) translate(-24px, -20px);
  box-shadow: 17px 35px 21px 4px rgba(0, 0, 0, 0.18);
  z-index: 25;
}
.classes-list > div:nth-child(odd) .class-wrapper.clicked {
  transform: scale(1.1) translate(24px, -20px);
  box-shadow: -17px 35px 21px 4px rgba(0, 0, 0, 0.18);
  z-index: 25;
}*/
</style>
